import classNames from "classnames";
import React from "react";
import { useExpanded } from "../common/store";
import { CardsContainerProps } from "./definitions";

export const CardsContainer = ({
  children,
  className,
}: CardsContainerProps) => {
  const expanded = useExpanded();

  if (children.length > 2) {
    console.error("Children inside CardContainer must not be more than 2.");
    return <div>Children inside CardContainer must not be more than 2.</div>;
  }

  return (
    <section
      className={`h-full w-full grid grid-cols-12 grid-rows-6 gap-16 ${className}`}
    >
      {/* if not an array, then expand the 12 columns */}
      {!children.length && (
        <div className="h-full w-full col-span-full row-span-full">
          {children}
        </div>
      )}

      {/* if an array, share 50/50 columns unless expanded */}
      {children.length === 2 && (
        <>
          <div
            className={`h-full w-full row-span-full ${classNames({
              [`col-span-6`]: !expanded,
            })} ${classNames({
              [`col-span-3`]: expanded,
            })}`}
          >
            {children[0]}
          </div>
          <div
            className={`h-full w-full row-span-full ${classNames({
              [`col-span-6`]: !expanded,
            })} ${classNames({
              [`col-span-9`]: expanded,
            })}`}
          >
            {children[1]}
          </div>
        </>
      )}
    </section>
  );
};
