import { LoadingOverlay } from "@mantine/core";
import { zodResolver, useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { House, VisitorType } from "src/common/api/types";
import { useClusterInfo, useHouseQuery } from "src/common/hooks";
import { visitorSchema, VisitorsPost } from "src/common/schemas/schemas";
import { useExpanded } from "src/common/store";
import { Button } from "src/components/Button";
import { Card } from "src/components/Card";
import { Checkbox } from "src/components/Checkbox";
import { ChipsInputGroup } from "src/components/ChipsInputGroup";
import { ColorInput } from "src/components/ColorInput";
import { Loader } from "src/components/Loader";
import { SelectInput } from "src/components/SelectInput";
import { Table } from "src/components/Table";
import { TextInput } from "src/components/TextInput";
import { Typography } from "src/components/Typography";
import { AllowAccessModal } from "src/pages/guard/components/modals/AllowAccessModal";
import { DenyAccessModal } from "src/pages/guard/components/modals/DenyAccessModal";
import { formatPhoneNumber } from "src/common/utils";
import colors from "src/common/colors";
import { UseFormReturnType } from "@mantine/form/lib/use-form";
import { showNotification } from "@mantine/notifications";
import { useMutation, useQuery } from "react-query";
import { getCarOptions, postVisitor } from "src/common/api";

export const AccessTab = () => {
  const [openDenyModal, setOpenDenyModal] = useState(false);
  const [openAllowModal, setOpenAllowModal] = useState(false);
  const [houseNumber, setHouseNumber] = useState("");
  const [debouncedHouseNumber] = useDebouncedValue(houseNumber, 500);
  const expanded = useExpanded();
  const {
    data: housesData,
    // isLoading: housesLoading,
    // isFetching: housesFetching,
    refetch: housesRefetch,
  } = useHouseQuery();
  const { data: carBrandsData } = useQuery(["carOptionsData"], () =>
    getCarOptions(),
  );

  const form = useForm({
    schema: zodResolver(visitorSchema),
    initialValues: {
      // TODO initial values should come from a store
      fname: "",
      lname: "",
      carModel: "",
      carBrand: "",
      carColor: "",
      carPlate: "",
      visitorType: "",
      houseId: "",
      grantedBy: "",
      wasGranted: false,
      locationId: "",
      timeStart: dayjs().toISOString(),
      timeEnd: dayjs().toISOString(),
      timeElapsed: 0,
      details: "",
      addedAt: dayjs().toISOString(),
    },
  });

  const cluster = useClusterInfo();

  const { mutate: submitPostVisitor, status: rqStatus } = useMutation(
    postVisitor,
    {
      onSuccess: (res) => {
        form.reset();
        setHouseNumber("");
        setOpenAllowModal(false);
        setOpenDenyModal(false);
        showNotification({
          title: "Success",
          message:
            res.statusCode === 202
              ? "Information submitted, but gate could not open."
              : "Information submitted.",
          color: res.statusCode === 202 ? "yellow" : "green",
        });
      },
      onError: (res: any) => {
        form.reset();
        setHouseNumber("");
        setOpenAllowModal(false);
        setOpenDenyModal(false);
        showNotification({
          title: "Error",
          message: "An error ocurred",
          color: "red",
        });
      },
    },
  );

  const handleHouseChange = (e: any) => {
    setHouseNumber(e);
    form.setFieldValue("houseId", e);
  };

  return (
    <Card expandable className="h-full w-full flex flex-col">
      {false && <LoadingOverlay visible loader={<Loader size={"lg"} />} />}
      {/* access modals */}
      <DenyAccessModal
        isOpen={openDenyModal}
        form={form}
        onClose={() => setOpenDenyModal(false)}
      />
      <AllowAccessModal
        isOpen={openAllowModal}
        form={form}
        onClose={() => setOpenAllowModal(false)}
      />

      {/* access inputs */}
      {Object.keys(form.errors).length > 0 && (
        <Typography state="error">{JSON.stringify(form.errors)}</Typography>
      )}
      <div className="flex justify-end">
        <Button size="xs" text="Reset Form" onClick={() => form.reset()} />
      </div>
      <div className="flex flex-wrap gap-5">
        <ChipsInputGroup
          onChange={(value) => {
            form.setFieldValue("visitorType", value);
          }}
          value={form.values.visitorType}
          chips={[
            { value: "VISITOR", children: "Visitor" },
            {
              value: "SERVICE",
              children: cluster ? `Service for ${cluster.name}` : "Service",
            },
            { value: "CONTRACTOR", children: "Contractor" },
          ]}
        />
      </div>
      <form
        id="visitorForm"
        className="flex flex-wrap gap-x-3 gap-y-1"
        // FIX figure out why TS complains here
        // @ts-ignore
        onSubmit={form.onSubmit((data) => submitPostVisitor(data))} //setDebugDataOutput(data))}
      >
        <SelectInput
          label="House"
          size="xs"
          options={
            !housesData
              ? []
              : housesData?.houses?.map((house: House) => {
                  return { value: house.id, label: house.number };
                })
          }
          onDropdownOpen={() => housesRefetch()}
          onSearchChange={(e) => setHouseNumber(e)}
          onChange={(e) => handleHouseChange(e)}
        />
        <TextInput
          label="Plate #"
          size="xs"
          {...form.getInputProps("carPlate")}
          value={form.values.carPlate}
        />
        <TextInput
          label="First Name"
          size="xs"
          {...form.getInputProps("fname")}
        />
        <TextInput
          label="Last Name"
          size="xs"
          {...form.getInputProps("lname")}
        />
        <SelectInput
          label="Car Brand"
          size="xs"
          // creatable
          {...form.getInputProps("carBrand")}
          options={
            !carBrandsData
              ? []
              : carBrandsData?.carBrands?.map((carBrand: any) => {
                  return { value: carBrand.name, label: carBrand.name };
                })
          }
          value={form.values.carBrand}
        />
        <SelectInput
          label="Model"
          size="xs"
          // creatable
          {...form.getInputProps("carModel")}
          // options={["Corolla", "Rav4", "Yaris"]}
          options={
            !carBrandsData
              ? []
              : [
                  ...carBrandsData?.carBrands
                    .filter(
                      (carBrand: any) => carBrand.name === form.values.carBrand,
                    )
                    .map((carBrand: any) => {
                      return carBrand?.brandModels?.map((model: any) => {
                        return model.name;
                      });
                    })
                    .flat(),
                ]
          }
          value={form.values.carModel}
        />
        <ColorInput
          label="Color"
          size="xs"
          showColorSwatches
          {...form.getInputProps("carColor")}
        />
      </form>

      {/* House access table */}
      <AccessDataTable
        debounce={debouncedHouseNumber}
        form={form}
        houseData={
          housesData
            ? housesData?.houses?.filter(
                (house: House) => house.number === debouncedHouseNumber,
              )
            : []
        }
      />

      {/* allow or deny access buttons */}
      <div className="mt-3 w-fit self-end flex gap-3">
        <Button
          text="Deny Access"
          size="sm"
          variant="outlined"
          color="error"
          onClick={() => {
            form.setFieldValue("wasGranted", false);
            setOpenDenyModal(true);
          }}
        />
        {/* //TODO allow button should be enabled when an access checkbox on house table is checked */}
        <Button
          text="Allow Access"
          size="sm"
          variant="outlined"
          onClick={() => {
            form.setFieldValue("wasGranted", true);
            setOpenAllowModal(true);
          }}
        />
      </div>
    </Card>
  );
};

const AccessDataTable = ({
  houseData,
  debounce,
  form,
}: {
  houseData: any;
  debounce: string;
  form: any | UseFormReturnType<VisitorsPost>;
}) => {
  /** The house data based on house number, singular data */

  return (
    <Card className="mt-5 flex-1 overflow-y-auto">
      <Typography type="subheader" state="info" fontState="medium">
        House{" "}
        <span className="ml-3 underline">
          {houseData[0]?.number &&
            `${houseData[0]?.number} ${houseData[0]?.address}`}
        </span>
      </Typography>

      {/* //TODO fix overflow y issue, its not triggering on this Card*/}
      {houseData.length === 0 ? (
        <div className="flex items-center justify-center h-2/3">
          <Typography state="info" fontState="semibold">
            {debounce
              ? "House not found"
              : "Type house number to start access process"}
          </Typography>
        </div>
      ) : (
        <Table
          type="card"
          fontSize="sm"
          columns={[
            "Resident",
            "Phone",
            "A. Priority",
            "Status",
            "Permit",
            // "Pin",
          ]}
          rowsData={[
            ...houseData[0].mainContact,
            ...houseData[0].residents,
          ].map((data: any) => {
            const { fname, lname, lname2, phone, isMainContact, active } = data;

            return {
              resident: `${fname ? fname : ""} ${lname ? lname : ""} ${
                lname2 ? lname2 : ""
              }`,
              phone: formatPhoneNumber(phone),
              priority: (
                <Typography state={isMainContact ? "info" : undefined}>
                  {isMainContact ? "Primary" : "Secondary"}
                </Typography>
              ),
              status: active ? (
                <FaCheck color={colors.primary} />
              ) : (
                <FaTimes color={colors.error} />
              ),
              permit: (
                <Checkbox
                  value={data.id}
                  // form="visitorForm"
                  // checked={permit}
                  checked={form.values.grantedBy.includes(data.id)}
                  onChange={(e) =>
                    form.setFieldValue("grantedBy", e.target.value)
                  }
                />
              ),
            };
          })}
        />
      )}
    </Card>
  );
};
