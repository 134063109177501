import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { postVisitor } from "src/common/api";
import { useAccessPoints } from "src/common/hooks";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import { SelectInput } from "src/components/SelectInput";
import { TextAreaInput } from "src/components/TextAreaInput";

export const DenyAccessModal = ({
  isOpen,
  onClose,
  form,
}: //
{
  isOpen: boolean;
  onClose: () => void;
  form: any;
}) => {
  const {
    mutate: submitPostVisitor,
    // data,
    status: rqStatus,
  } = useMutation(postVisitor, {
    onSuccess: () => {
      onClose();
      showNotification({
        title: "Success",
        message: "Access was denied",
        color: "red",
      });
    },
  });
  const accessPoints = useAccessPoints();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Deny Access"
      type="error"
      size="lg"
    >
      <SelectInput
        label="Select Access Point to open gate"
        value={form.values.locationId}
        onChange={(e) => form.setFieldValue("locationId", e)}
        options={accessPoints.map((ap: any) => ({
          value: ap.id,
          label: ap.name,
        }))}
      />
      {/* // UGLY add className prop to select and textarea */}
      <div className="my-2"></div>
      <TextAreaInput
        label="Reason for denying access?"
        placeholder="Reason for denying the access?"
        minRows={4}
        {...form.getInputProps("details")}
      />

      <div className="flex gap-3 justify-end mt-3">
        <Button
          text="Cancel"
          color="error"
          variant="outlined"
          size="xs"
          onClick={onClose}
        />
        <Button text="Confirm" size="xs" type="submit" form="visitorForm" />
        {/* <Button
          text="Confirm"
          size="xs"
          onClick={() =>
            // TODO should be at form confirm
            // TODO use values from global state
            submitPostVisitor({
              fname: "First",
              lname: "Last",
              carModel: "Corolla",
              carBrand: "Toyota",
              carColor: "#ff0000",
              carPlate: "ABC-124",
              visitorType: "VISITOR",
              // guardId: "cl38dvh2707y30799aujdtjhz",
              houseId: "cl3phkw7u2fyf0770cbsu9uji",
              grantedBy: "cl3phksky2fsr0770smfou0gp",
              wasGranted: true,
              locationId: locationId ?? "cl3pgrbzr2f2m07703clbrr01",
              timeStart: dayjs().toISOString(),
              timeEnd: dayjs().toISOString(),
              timeElapsed: 0,
              // faceImage: "string",
              // plateImage: "string",
              details: "No one answered",
              addedAt: dayjs().toISOString(),
            })
          }
        /> */}
      </div>
    </Modal>
  );
};
