import create, { SetState, GetState } from "zustand";
import { persist } from "zustand/middleware";

type Expand = {
  expanded: boolean;
  setExpanded: (expandedState: boolean) => void;
};
// const middleware = (f: any) => persist(f);

const useExpand = create<Expand>()(
  persist(
    (set) => ({
      expanded: true,
      setExpanded: (expandedState: boolean): void => {
        set({ expanded: expandedState });
      },
    }),
    { name: "expand" },
  ),
);

export const useExpanded = () => useExpand((state) => state.expanded);
export const useSetExpanded = () => useExpand((state) => state.setExpanded);
