import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FaAngleDoubleRight, FaUser } from "react-icons/fa";
import { useMutation } from "react-query";
import { postVisitor } from "src/common/api";
import colors from "src/common/colors";
import { useAccessPoints } from "src/common/hooks";
import { AlertingText } from "src/components/AlertingText";
import { Button } from "src/components/Button";
import { Modal } from "src/components/Modal";
import { SelectInput } from "src/components/SelectInput";
import { TextAreaInput } from "src/components/TextAreaInput";
import { Typography } from "src/components/Typography";

export const AllowAccessModal = ({
  isOpen,
  onClose,
  form,
}: {
  isOpen: boolean;
  onClose: () => void;
  form: any;
}) => {
  const {
    mutate: submitPostVisitor,
    // data,
    status: rqStatus,
  } = useMutation(postVisitor, {
    onSuccess: () => {
      onClose();
      showNotification({
        title: "Success",
        message: "Visitor was added successfully",
        color: "teal",
      });
    },
  });
  const accessPoints = useAccessPoints();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Allow Access"
      type="info"
      size="lg"
    >
      <div>
        {/* access info header */}
        {/* <div>
              <Typography
                className="text-[#837A7A]"
                type="subheader"
                fontState="semibold"
              >
                Access Information
              </Typography>
            </div> */}
        {/* people info */}
        {/* border-b-2 border-b-black */}
        <TextAreaInput
          placeholder="Additional Details"
          minRows={4}
          {...form.getInputProps("details")}
          // value={form.values.details}
        />
        <div className="flex gap-5 justify-between items-center my-5">
          <div className="flex items-center gap-3">
            <FaUser className="mr-5" color={colors.primary} size={"2rem"} />

            <Typography fontState="medium" label="Name">
              {form.values.fname ? form.values.fname : "Select name"}
            </Typography>

            <Typography fontState="medium" label="Surnames">
              {form.values.lname ? form.values.lname : "Select name"}
            </Typography>
          </div>
          <SelectInput
            label="Select Access Point to open gate"
            value={form.values.locationId}
            onChange={(e) => form.setFieldValue("locationId", e)}
            options={accessPoints.map((ap: any) => ({
              value: ap.id,
              label: ap.name,
            }))}
          />

          {/* car info */}
          {/* <div className="flex items-center gap-10 p-2">
                <FaCarSide className="mr-5" color="#0D9E90" size={"2rem"} />

                <Typography fontState="medium" label="Brand">
                  Thing
                </Typography>

                <Typography fontState="medium" label="Model">
                  Thing
                </Typography>

                <Typography fontState="medium" label="Color">
                  Thing
                </Typography>

                <Typography fontState="medium" label="Plate">
                  Thing
                </Typography>
              </div> */}
        </div>
      </div>

      {/* TODO own component? */}
      {/* TODO have each model elements own their own row (Deny Access flow) */}
      <div className="flex gap-3 justify-between items-center mt-3">
        {/* user to location message */}
        <AlertingText
          type="primary"
          className="flex gap-3 justify-center items-center"
        >
          <Typography state="info">User</Typography>
          <FaAngleDoubleRight />
          <Typography state="info">Selected Gate</Typography>
        </AlertingText>

        {/* modal buttons */}
        <span className="flex gap-3 justify-center items-center">
          <Button
            text="Cancel"
            color="error"
            variant="outlined"
            size="xs"
            onClick={onClose}
          />
          <Button text="Confirm" size="xs" type="submit" form="visitorForm" />
          {/* <Button
            text="Confirm"
            size="xs"
            onClick={() =>
              // TODO should be at form confirm
              // TODO use values from global state
              submitPostVisitor({
                fname: "First",
                lname: "Last",
                carModel: "Corolla",
                carBrand: "Toyota",
                carColor: "#ff0000",
                carPlate: "ABC-124",
                visitorType: "VISITOR",
                // guardId: "cl38dvh2707y30799aujdtjhz",
                houseId: "cl3phkw7u2fyf0770cbsu9uji",
                grantedBy: "cl3phksky2fsr0770smfou0gp",
                wasGranted: true,
                locationId: locationId ?? "cl3pgrbzr2f2m07703clbrr01",
                timeStart: dayjs().toISOString(),
                timeEnd: dayjs().toISOString(),
                addedAt: dayjs().toISOString(),
                timeElapsed: 0,
                // faceImage: "string",
                // plateImage: "string",
                details: "No one answered",
                // addedAt: new Date(),
              })
            }
          /> */}
          {/* {
        "fname": "First",
        "lname": "Last",
        "carModel": "Corolla",
        "carBrand": "Toyota",
        "carColor": "#ff0000",
        "carPlate": "ABC-124",
        "visitorType": "VISITOR",
        "guardId": "cl38dvh2707y30799aujdtjhz",
        "houseId": "cl3phkw7u2fyf0770cbsu9uji",
        "grantedBy": "cl3phksky2fsr0770smfou0gp",
        "wasGranted": true,
        "locationId": "cl3pgrbzr2f2m07703clbrr01",
        "timeStart": "2022-05-28T07:30:09.851Z",
        "timeEnd": "2022-05-28T07:30:09.851Z",
        "timeElapsed": 0,
        "faceImage": "string",
        "plateImage": "string",
        "details": "",
        "addedAt": "2022-05-28T07:30:09.851Z",
          "details": "No one answered"
      } */}
        </span>
      </div>
    </Modal>
  );
};
