import { Log } from "./types";
import axios from "axios";
import { headersAuth } from "src/common/auth";
import { LoginPost, VisitorsPost } from "src/common/schemas/schemas";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers: { "Content-Type": "application/json" }, //, "Cache-Control": "no-cache" },
});

export const postLogin = async (body: LoginPost): Promise<any> => {
  const { data } = await axiosClient.post<any>("/login", JSON.stringify(body));
  return data;
};

export const postVisitor = async (body: VisitorsPost): Promise<any> => {
  const { data } = await axiosClient.post<any>(
    "/guard/visitors",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const getCarOptions = async (params?: any): Promise<any> => {
  const { data } = await axiosClient.get("/car-options", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getLogs = async (params?: any): Promise<Log[]> => {
  const { data } = await axiosClient.get<Log[]>("/logs", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getAccessKeys = async (params?: any): Promise<any> => {
  const { data } = await axiosClient.get<any>("/access-keys", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getUser = async ({
  userId,
  token,
}: {
  userId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>(`/users/${userId}`, {
    headers: headersAuth(token),
  });

  return data;
};

export const getHouses = async ({
  clusterId,
  token,
}: {
  clusterId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>(`/houses`, {
    headers: headersAuth(token),
    params: { cid: clusterId },
  });
  return data;
};
