import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { TypographyProps } from "./definitions";

export const Typography = ({
  children,
  type = "regular",
  state,
  label,
  fontState,
  pulsing,
  className,
}: TypographyProps) => {
  const [typeStyle, setTypeStyle] = useState("");
  const [stateStyle, setStateStyle] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const omega = () => {
    switch (type) {
      case "header":
        setTypeStyle("text-xl");
        break;
      case "subheader":
        setTypeStyle("text-lg");
        break;
      case "regular":
      default:
        setTypeStyle("text-base");
        break;
    }

    switch (state) {
      case "info":
        setStateStyle("!text-primary");
        break;
      case "warning":
        setStateStyle("!text-warning");
        break;
      case "error":
        setStateStyle("!text-error");
        break;
      case "muted":
        setStateStyle("!text-slate-300");
        break;
      default:
        setStateStyle("text-black");
    }

    switch (fontState) {
      case "medium":
        setFontStyle("font-medium");
        break;
      case "bold":
        setFontStyle("font-bold");
        break;
      case "semibold":
        setFontStyle("font-semibold");
        break;
      case "extrabold":
        setFontStyle("font-extrabold");
        break;
      case "normal":
      default:
        setFontStyle("font-normal");
        break;
    }
  };
  useMemo(() => omega(), [type, state, fontState]);

  return (
    <span
      className={`${typeStyle} ${stateStyle} ${fontStyle} ${classNames({
        "animate-pulse": pulsing,
      })} ${className}`}
    >
      {label && <p className="text-xs text-[#837A7A]">{label}</p>}
      {children}
    </span>
  );
};
