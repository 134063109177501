import classNames from "classnames";
import React, { useState } from "react";
import { useExpanded } from "src/common/store";
import { Card } from "src/components/Card";
import { CardsContainer } from "src/components/CardsContainer";
import { ImageContainer } from "src/components/ImageContainer";
import { Tab as TabContainer } from "src/components/Tab";
import { Typography } from "src/components/Typography";
import { AccessTab } from "src/pages/guard/components/AccessTab";
import { HouseTab } from "src/pages/guard/components/HouseTab";
import { ResidentsTab } from "src/pages/guard/components/ResidentsTab";

export const GuardPage = () => {
  const expanded = useExpanded();

  const [debugDataOutput, setDebugDataOutput] = useState<object | null>();

  return (
    <CardsContainer className="pl-20">
      {/* gate cameras */}

      <Card className="h-full w-full overflow-y-scroll">
        {/* <div className="sticky top-0 z-50 bg-red-400"> */}
        <Typography
          // className="sticky top-0 z-50 bg-white"
          type="header"
          state="info"
          fontState="bold"
        >
          Cameras
        </Typography>
        {/* </div> */}
        {process.env.NODE_ENV === "development" &&
          JSON.stringify(debugDataOutput)}

        {/* gate images */}
        <div className="my-5 flex flex-col">
          {/* <div className="w-[10%] self-end">
            <ImageScanButton />
          </div> */}
          <div className="flex justify-between gap-5 flex-wrap">
            <div
              className={`${classNames(
                { "flex-1": !expanded },
                { "w-full": expanded },
              )}`}
            >
              <Typography type="subheader" state="info" fontState="semibold">
                Driver
              </Typography>
              <ImageContainer
                image={""}
                // image={
                //   "https://images.pexels.com/photos/4916624/pexels-photo-4916624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                // }
              />
            </div>

            <div
              className={`${classNames(
                { "flex-1": !expanded },
                { "w-full": expanded },
              )}`}
            >
              <Typography type="subheader" state="info" fontState="semibold">
                Plate
              </Typography>
              <ImageContainer image={""} />
            </div>
          </div>
        </div>

        {/* camera stations */}
        {/* <div>
          <Accordion
            items={[
              {
                label: <Typography state="info">Camera Station</Typography>,
                children: (
                  <div>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Error nemo dolore eum consequuntur laudantium tempore
                    consectetur autem nesciunt incidunt quae, provident, impedit
                    aliquam quas aliquid libero numquam ipsam labore
                    accusantium?
                  </div>
                ),
              },
            ]}
          />
        </div> */}
      </Card>

      {/* // TODO fix overflow issues, on either style overflow-y-auto */}
      <TabContainer
        rootStyle="h-full w-full rounded-lg flex flex-col"
        bodyStyle="p-0 h-full "
        tabs={[
          {
            title: "Access",
            content: <AccessTab />,
          },
          // {
          //   title: "Resident",
          //   content: <ResidentsTab />,
          // },
          {
            title: "House",
            content: <HouseTab />,
          },
        ]}
      />
    </CardsContainer>
  );
};
