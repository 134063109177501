import classNames from "classnames";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  FaCarSide,
  FaCheck,
  FaLaptopHouse,
  FaQrcode,
  FaSearch,
  FaTag,
  FaTimes,
  FaWalking,
} from "react-icons/fa";
import colors from "src/common/colors";
import { AuthVisitor, Log, VisitorLog } from "../../common/api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { CardsContainer } from "../../components/CardsContainer";
import { Loader } from "../../components/Loader";
import { Tab } from "../../components/Tab";
import { Table } from "../../components/Table";
import { TextInput } from "../../components/TextInput";
import { Tooltip } from "../../components/Tooltip";
import { Typography } from "../../components/Typography";

export const Logs = () => {
  return (
    <CardsContainer className="pl-20">
      <Tab
        rootStyle="h-full w-full flex flex-col"
        bodyStyle="h-[92%]"
        tabPadding={0}
        tabs={[
          {
            title: "Log",
            content: (
              <>
                {/* table search */}
                <div className="flex justify-end gap-3 absolute top-3 right-14">
                  {/* search input */}
                  <TextInput placeholder="Search name or house..." size="xs" />

                  {/* search button */}
                  <Button text="" icon={<FaSearch />} size="xs" />
                </div>

                {/* table */}
                <Card className="h-full w-full overflow-y-auto pt-0">
                  <LogsDataTable />
                </Card>
              </>
            ),
          },
          {
            title: "Authorize Visitors",
            content: (
              <>
                {/* table legend for people type */}

                <div className="flex justify-end gap-3 absolute top-3 right-14">
                  {/* service */}
                  <AuthVisitorsTypeCountPill type="service" />

                  {/* visitors */}
                  <AuthVisitorsTypeCountPill type="visitor" />

                  {/* contractors */}
                  <AuthVisitorsTypeCountPill type="contractor" />
                </div>

                {/* table */}
                <Card className="h-full w-full overflow-y-auto pt-0">
                  <AuthVisitorsDataTable />
                </Card>
              </>
            ),
          },
          {
            title: "Visitors Log",
            content: (
              <>
                {/* table legend for people type */}

                <div className="flex justify-end gap-3 absolute top-3 right-14">
                  {/* service */}
                  <VisitorsTypeCountPill type="service" />

                  {/* visitors */}
                  <VisitorsTypeCountPill type="visitor" />

                  {/* contractors */}
                  <VisitorsTypeCountPill type="contractor" />
                </div>

                {/* table */}
                <Card className="h-full w-full overflow-y-auto pt-0">
                  <VisitorLogsDataTable />
                </Card>
              </>
            ),
          },
        ]}
      />
    </CardsContainer>
  );
};

const LogsDataTable = () => {
  /** The resident's data */
  const [logsData, setLogsData] = useState<Log[]>();

  const fetchLogsData = async () => {
    await fetch("logsData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        setLogsData(json);
      });
  };

  useEffect(() => {
    fetchLogsData();
  }, []);

  return !logsData ? (
    <div className="flex items-center justify-center h-full">
      <Loader size={"lg"} />
    </div>
  ) : (
    <Table
      fontSize={"xs"}
      tableHeaderStyle="sticky top-0 bg-white z-10"
      columns={[
        "Key",
        "Method",
        "Time",
        "Name",
        "House",
        "Approval",
        "Type",
        "Access Point",
      ]}
      rowsData={logsData.map((log) => {
        const {
          key: { type: keyType, value },
          method,
          time,
          user: { fname, lname, lname2 },
          house,
          type,
          status,
          accessPoint,
        } = log;
        const checkTooltipIcon = (method: string | null | undefined) => {
          if (method === "QRCODE")
            return <FaQrcode color={colors.primary} size={"1.5rem"} />;
          if (method === "RFID")
            return <FaTag color={colors.primary} size={"1.5rem"} />;
          if (method === "GUARDPOST")
            return <FaLaptopHouse color={colors.primary} size={"1.5rem"} />;

          return null;
        };
        const checkKeyMethod = (method: string | null | undefined) => {
          if (method === "QRCODE") {
            return (
              <>
                <FaQrcode />
                {value}
              </>
            );
          }

          if (method === "RFID") {
            return (
              <>
                <FaTag />
                {value}
              </>
            );
          }

          if (method === "GUARDPOST") {
            return (
              <>
                <FaLaptopHouse />
                {value}
              </>
            );
          }

          return null;
        };
        const checkEntryMethod = (method: string | null | undefined) => {
          if (method === "PEDESTRIAN")
            return <FaWalking color={colors.primary} size={"1.5rem"} />;
          if (method === "VEHICLE")
            return <FaCarSide color={colors.primary} size={"1.5rem"} />;

          return null;
        };

        return {
          key: (
            <Tooltip
              label={
                <div className="flex flex-wrap flex-col gap-3">
                  <p className="self-center">Access Methods</p>

                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3 items-center">
                      {checkKeyMethod(keyType)}
                    </div>
                  </div>
                </div>
              }
            >
              {checkTooltipIcon(keyType)}
            </Tooltip>
          ),
          method: checkEntryMethod(method),
          time: dayjs(time).format("MMM/DD - HH:mm"),
          user: `${fname} ${lname} ${lname2}`,
          house,
          status: status ? (
            <FaCheck color={colors.primary} size={"1.5rem"} />
          ) : (
            <FaTimes color={colors.error} size={"1.5rem"} />
          ),
          type,
          accessPoint,
        };
      })}
    />
  );
};

const AuthVisitorsTypeCountPill = ({ type }: { type: string }) => {
  /** The visitor logs data */
  const [authVisitorsData, setAuthVisitorsData] = useState<AuthVisitor[]>();

  const fetchAuthVisitorsData = async () => {
    await fetch("authVisitorsData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        setAuthVisitorsData(json);
      });
  };

  useEffect(() => {
    fetchAuthVisitorsData();
  }, []);

  return (
    <span
      className={`${classNames(
        { "bg-warning": type === "service" },
        { "bg-error": type === "visitor" },
        { "bg-primary": type === "contractor" },
      )} px-3 py-1 rounded-full text-white flex items-center gap-3`}
    >
      {`${type.charAt(0).toUpperCase() + type.slice(1)} in Cluster`}{" "}
      <span className="bg-white text-black rounded-full px-2 text-sm">
        {!authVisitorsData ? (
          <Loader size={"xs"} variant={"oval"} />
        ) : (
          authVisitorsData.filter(
            (visitorLog) => visitorLog.visitorType === type.toUpperCase(),
          ).length
        )}
      </span>
    </span>
  );
};

const AuthVisitorsDataTable = () => {
  /** The visitor logs data */
  const [authVisitorsData, setAuthVisitorsData] = useState<AuthVisitor[]>();

  const fetchAuthVisitorsData = async () => {
    await fetch("authVisitorsData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        setAuthVisitorsData(json);
      });
  };

  useEffect(() => {
    fetchAuthVisitorsData();
  }, []);

  return !authVisitorsData ? (
    <div className="flex items-center justify-center h-full">
      <Loader size={"lg"} />
    </div>
  ) : (
    <Table
      fontSize={"xs"}
      tableHeaderStyle="sticky top-0 bg-white z-10"
      columns={[
        "House",
        "Authorized by",
        "Name",
        "Key",
        "Access/Schedule",
        "Status",
        "Date Created",
        "Last Used",
        "Authorized Visitor",
      ]}
      rowsData={
        authVisitorsData &&
        authVisitorsData.map((visitorLog) => {
          const {
            house: { number },
            authorizedBy: {
              fname: authByFname,
              lname: authByLname,
              lname2: authByLname2,
            },
            user: { fname: userFname, lname: userLname, lname2: userLname2 },
            visitorType,
            key: { type, value },
            accessSchedule,
            status,
            createdAt,
            updatedAt,
          } = visitorLog;
          const checkVisitorType = (visitorType: string | null | undefined) => {
            if (visitorType === "VISITOR") return "error";
            if (visitorType === "CONTRACTOR") return "info";
            if (visitorType === "SERVICE") return "warning";

            return undefined;
          };
          const checkTooltipIcon = (method: string | null | undefined) => {
            if (method === "QRCODE")
              return <FaQrcode color={colors.primary} size={"1.5rem"} />;
            if (method === "RFID")
              return <FaTag color={colors.primary} size={"1.5rem"} />;
            if (method === "GUARDPOST")
              return <FaLaptopHouse color={colors.primary} size={"1.5rem"} />;

            return null;
          };
          const checkInMethod = (method: string | null | undefined) => {
            if (method === "QRCODE") {
              return (
                <>
                  <FaQrcode />
                  {value}
                </>
              );
            }

            if (method === "RFID") {
              return (
                <>
                  <FaTag />
                  {value}
                </>
              );
            }

            if (method === "GUARDPOST") {
              return (
                <>
                  <FaLaptopHouse />
                  {value}
                </>
              );
            }

            return null;
          };

          return {
            house: number,
            authorizedBy: `${authByFname} ${authByLname} ${authByLname2}`,
            name: (
              <Typography
                className="text-xs"
                state={checkVisitorType(visitorType)}
                fontState="medium"
              >
                {`${userFname} ${userLname} ${userLname2}`}
              </Typography>
            ),
            key: (
              <Tooltip
                label={
                  <div className="flex flex-wrap flex-col gap-3">
                    <p className="self-center">Access Methods</p>

                    <div className="flex flex-col gap-3">
                      <div className="flex gap-3 items-center">
                        {checkInMethod(type)}
                      </div>
                    </div>
                  </div>
                }
              >
                {checkTooltipIcon(type)}
              </Tooltip>
            ),
            accessSchedule: dayjs(accessSchedule).format("ddd - HH:mm - HH:mm"),
            status: (
              <Typography state={status ? "info" : "error"} fontState="medium">
                {status ? "Active" : "Blocked"}
              </Typography>
            ),
            createdAt: dayjs(createdAt).format("MMM/DD - HH:mm"),
            updatedAt: dayjs(updatedAt).format("MMM/DD - HH:mm"),
            // authorizeVisitor: "put select input w/ button here",
          };
        })
      }
    />
  );
};

const VisitorsTypeCountPill = ({ type }: { type: string }) => {
  /** The visitor logs data */
  const [visitorLogsData, setVisitorLogsData] = useState<VisitorLog[]>();

  const fetchVisitorLogsData = async () => {
    await fetch("visitorLogsData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        setVisitorLogsData(json);
      });
  };

  useEffect(() => {
    fetchVisitorLogsData();
  }, []);

  return (
    <span
      className={`${classNames(
        { "bg-warning": type === "service" },
        { "bg-error": type === "visitor" },
        { "bg-primary": type === "contractor" },
      )} px-3 py-1 rounded-full text-white flex items-center gap-3`}
    >
      {`${type.charAt(0).toUpperCase() + type.slice(1)} in Cluster`}{" "}
      <span className="bg-white text-black rounded-full px-2 text-sm">
        {!visitorLogsData ? (
          <Loader size={"xs"} variant={"oval"} />
        ) : (
          visitorLogsData.filter(
            (visitorLog) => visitorLog.visitorType === type.toUpperCase(),
          ).length
        )}
      </span>
    </span>
  );
};

const VisitorLogsDataTable = () => {
  /** The visitor logs data */
  const [visitorLogsData, setVisitorLogsData] = useState<VisitorLog[]>();

  const fetchVisitorLogsData = async () => {
    await fetch("visitorLogsData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((json) => {
        // console.log(json);
        setVisitorLogsData(json);
      });
  };

  useEffect(() => {
    fetchVisitorLogsData();
  }, []);

  return !visitorLogsData ? (
    <div className="flex items-center justify-center h-full">
      <Loader size={"lg"} />
    </div>
  ) : (
    <Table
      fontSize={"xs"}
      tableHeaderStyle="sticky top-0 bg-white z-10"
      columns={[
        "Name",
        "House",
        "Approved",
        "Approver",
        "Check-in Method",
        "Check-in Access Point",
        "Check-in Time",
        // "Check-out Method",
        // "Check-out Access Point",
        // "Check-out Time",
      ]}
      rowsData={
        visitorLogsData &&
        visitorLogsData.map((visitorLog) => {
          const {
            user: { fname: userFname, lname: userLname, lname2: userLname2 },
            visitorType,
            house: { number },
            approved,
            approver: {
              fname: approverFname,
              lname: approverLname,
              lname2: approverLname2,
            },
            checkInMethod: { type, value },
            checkInAccessPoint,
            checkInTime,
          } = visitorLog;
          const checkVisitorType = (visitorType: string | null | undefined) => {
            if (visitorType === "VISITOR") return "error";
            if (visitorType === "CONTRACTOR") return "info";
            if (visitorType === "SERVICE") return "warning";

            return undefined;
          };
          const checkTooltipIcon = (method: string | null | undefined) => {
            if (method === "QRCODE")
              return <FaQrcode color={colors.primary} size={"1.5rem"} />;
            if (method === "RFID")
              return <FaTag color={colors.primary} size={"1.5rem"} />;
            if (method === "GUARDPOST")
              return <FaLaptopHouse color={colors.primary} size={"1.5rem"} />;

            return null;
          };
          const checkInMethod = (method: string | null | undefined) => {
            if (method === "QRCODE") {
              return (
                <>
                  <FaQrcode />
                  {value}
                </>
              );
            }

            if (method === "RFID") {
              return (
                <>
                  <FaTag />
                  {value}
                </>
              );
            }

            if (method === "GUARDPOST") {
              return (
                <>
                  <FaLaptopHouse />
                  {value}
                </>
              );
            }

            return null;
          };

          return {
            name: (
              <Typography
                className="text-xs"
                state={checkVisitorType(visitorType)}
                fontState="medium"
              >
                {`${userFname} ${userLname} ${userLname2}`}
              </Typography>
            ),
            house: number,
            approved: approved ? (
              <FaCheck color={colors.primary} size={"1.5rem"} />
            ) : (
              <FaTimes color={colors.error} size={"1.5rem"} />
            ),
            approver: `${approverFname} ${approverLname} ${approverLname2}`,
            checkInMethod: (
              <Tooltip
                label={
                  <div className="flex flex-wrap flex-col gap-3">
                    <p className="self-center">Access Methods</p>

                    <div className="flex flex-col gap-3">
                      <div className="flex gap-3 items-center">
                        {checkInMethod(type)}
                      </div>
                    </div>
                  </div>
                }
              >
                {checkTooltipIcon(type)}
              </Tooltip>
            ),
            checkInAccessPoint,
            checkInTime: dayjs(checkInTime).format("MMM/DD - HH:mm"),
          };
        })
      }
    />
  );
};
