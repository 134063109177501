import classNames from "classnames";
import React from "react";
import { useExpanded, useSetExpanded } from "../common/store";
import { ExpandCard } from "./assets/icons";
import { ContentCardProps } from "./definitions";

export const Card = ({ children, expandable, className }: ContentCardProps) => {
  const expanded = useExpanded();
  const setExpanded = useSetExpanded();

  return (
    <div className={`bg-white p-5 shadow-xl rounded-lg relative ${className}`}>
      {expandable && (
        <button
          className="bg-white rounded-lg shadow-xl aspect-square grid place-items-center px-2 py-4 absolute left-[-1.5em] top-[50%] hover:bg-gray-200"
          onClick={() => setExpanded(!expanded)}
        >
          <ExpandCard
            className={`${classNames({
              "rotate-180": expanded,
            })}`}
            fill="black"
          />
        </button>
      )}

      {children}
    </div>
  );
};
