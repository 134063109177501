import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Camera, Expand } from "./assets/icons";
import { ImageContainerProps } from "./definitions";
import { Modal } from "./Modal";

export const ImageContainer = ({
  icon = (
    <Camera
      className="stroke-primary fill-white"
      height={"50%"}
      width={"50%"}
    />
  ),
  fullscreenControl,
  image,
  className,
}: ImageContainerProps) => {
  // NOTE the size will be determined by container and image provided
  // NOTE so adjust the image according to its container, this is to make it responsive. This is all possible because of w-fit className
  const [withImageStyle, setWithImageStyle] = useState("");
  const [openFullScrImg, setOpenFullScrImg] = useState(false);

  useEffect(() => {
    setWithImageStyle(
      classNames({ "opacity-25 hover:bg-white hover:opacity-100": image }),
    );

    return setOpenFullScrImg(false);
  }, []);

  return (
    // <div className="bg-red-400 w-full h-[90%]">
    <div
      className={`bg-white p-3 rounded-xl shadow-xl flex justify-center items-center w-full aspect-[1/1.5] relative object-cover ${className}`}
    >
      <Modal isOpen={openFullScrImg} onClose={setOpenFullScrImg} size="90%">
        <img className="h-full w-full rounded-xl" src={image} alt="image" />
      </Modal>

      {(fullscreenControl || image) && (
        <button
          className={`bg-white w-fit h-[12%] absolute top-5 right-5 p-3 rounded-lg grid place-items-center hover:bg-gray-200 ${withImageStyle} transition`}
          onClick={() => setOpenFullScrImg(true)}
        >
          <Expand height={"100%"} width={"100%"} />
        </button>
      )}

      {/* TODO dynamically add alt for accesibility */}
      {!image ? (
        icon
      ) : (
        <img
          className="h-full w-full rounded-xl"
          src={image}
          alt="gate camera image"
        />
      )}
    </div>
    // </div>
  );
};
