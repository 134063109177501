import { z } from "zod";
import dayjs from "dayjs";

// Schemas for POST endpoints and other data
// #region schemas
export const loginSchema = z.object({
  email: z
    .string()
    .email({ message: "Please provide email registered by the system" }),
  password: z.string().nonempty({ message: "Please provide your password" }),
});

export const visitorSchema = z.object({
  fname: z.string().nonempty({ message: "Required" }),
  lname: z.string().nonempty({ message: "Required" }),
  carBrand: z.string().nonempty({ message: "Required" }), //enum? 😢
  carModel: z.string().nonempty({ message: "Required" }), //enum?
  carColor: z.string().nonempty({ message: "Required" }),
  carPlate: z.string().nonempty({ message: "Required" }),
  visitorType: z.enum(["VISITOR", "CONTRACTOR", "SERVICE"]),
  // guardId: z.string().cuid( { message: "Guard id at request, if required" }), //logged in guard
  houseId: z.string().cuid({ message: "Must choose a house" }),
  grantedBy: z.string().cuid({ message: "Must be a valid user" }), //the selected person on house table in access tab it the id of the person, the permit checkmark NOT REQUIRED
  wasGranted: z.boolean(), //either clicked and confirmed deny / access modals. one clicked on either deny or access buttons. deny for false, access for true.
  locationId: z.string().cuid({ message: "Select an access point to open" }), // when clicked on accept or deny, the select input for the gate/site. [label:"location", value:"locationid"]
  timeStart: z.string().transform((time) => dayjs(time).toISOString()), // catch current time vienen del stopwatch component. use zustand store to store/get times
  timeEnd: z.string().transform((time) => dayjs(time).toISOString()), // same as timeStart
  addedAt: z.string().transform((date) => dayjs(date).toISOString()), // same as timeStart
  // timeElapsed: z.number(), // time elapsed in seconds, from stopwatch
  // faceImage: z.string().min(1, { message: "Required" }), // url of s3 bucket with image
  // plateImage: z.string().min(1, { message: "Required" }), // url of s3 bucket with image
  details: z.string(), // the details on deny access? //NOT REQUIRED, NEED TO BE THERE. the reason for access deny
});
// #endregion

// Generated types from zod schemas
// #region generated types
export type LoginPost = z.infer<typeof loginSchema>;

export type VisitorsPost = z.infer<typeof visitorSchema>;
// #endregion
