import React, { useEffect, useState } from "react";
import { Select as SelectMantine } from "@mantine/core";
import { SelectInputProps } from "./definitions";

export const SelectInput = ({
  label,
  placeholder,
  options,
  disabled,
  size,
  onChange,
  creatable,
  onDropdownOpen,
  onSearchChange,
  value,
}: SelectInputProps) => {
  const [data, setData] = useState(options);

  useEffect(() => {
    setData(options);
  }, [options]);

  return (
    <SelectMantine
      classNames={{
        input: `rounded-lg p-1 px-2 shadow border-1 border-[#666666] focus:border-2 focus:border-primary`,
      }}
      label={label}
      placeholder={placeholder}
      data={data}
      onDropdownOpen={onDropdownOpen}
      onSearchChange={onSearchChange}
      disabled={disabled}
      size={size}
      creatable={creatable}
      getCreateLabel={creatable ? (query) => `+ Create ${query}` : undefined}
      onCreate={
        creatable
          ? (query) => setData((current: typeof options) => [...current, query])
          : undefined
      }
      onChange={onChange}
      value={value}
      searchable
    />
  );
};
