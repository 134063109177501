import { useQuery, useQueryClient } from "react-query";
import { getHouses } from "src/common/api";

export function useHouseQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery(["housesData", clusterId], () =>
    getHouses({ clusterId }),
  );
  return response;
}

export function useAccessPoints() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const locations = user.clusters[0].locations;
  return locations;
}

export function useClusterInfo() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  return user.clusters[0];
}
