import React, { useEffect, useState } from "react";
import { Button as ButtonMantine } from "@mantine/core";
import { ButtonProps } from "./definitions";
import classNames from "classnames";

/** Primary UI component for user interaction */
export const Button = ({
  icon,
  iconPosition,
  text,
  color = "info",
  variant = "filled",
  size = "md",
  onClick,
  type,
  form,
  disabled,
  className,
  loading,
}: ButtonProps) => {
  const [buttonSize, setButtonSize] = useState("text-base");
  const [buttonColor, setButtonColor] = useState(
    "bg-primary text-white hover:bg-primary",
  );
  const [outlinedColor, setOutlinedColor] = useState(
    "outline-primary text-primary hover:bg-primary",
  );

  useEffect(() => {
    if (color === "info")
      setButtonColor("bg-primary text-white hover:bg-primary");
    if (color === "warning")
      setButtonColor("bg-warning text-white hover:bg-warning");
    if (color === "error") setButtonColor("bg-error text-white hover:bg-error");

    if (variant === "outlined" && color === "info")
      setOutlinedColor("border-primary text-primary hover:bg-primary");
    if (variant === "outlined" && color === "warning")
      setOutlinedColor("border-warning text-warning hover:bg-warning");
    if (variant === "outlined" && color === "error")
      setOutlinedColor("border-error text-error hover:bg-error");

    if (size === "md") {
      // its "base" for tailwind purposes
      setButtonSize("text-base");
    } else {
      if (size === "sm") setButtonSize("text-sm");
      if (size === "lg") setButtonSize("text-lg");
    }
  }, []);

  const filledStyle =
    variant === "filled" &&
    `${buttonColor} hover:drop-shadow-lg transition-shadow`;
  const outlinedStyle = classNames({
    [`border-2 ${outlinedColor} hover:text-white transition`]:
      variant === "outlined",
  });
  const disabledStyle = classNames({
    [`cursor-not-allowed grayscale text-white opacity-50 hover:drop-shadow-none transition-none`]:
      disabled,
  });

  // IDEA refactor classes using @apply
  return (
    <ButtonMantine
      loading={loading}
      className={`${filledStyle} ${outlinedStyle} px-4 py-1 rounded-lg ${buttonSize} font-semibold ${disabledStyle} ${className}`}
      onClick={onClick}
      type={type}
      form={form}
      disabled={disabled}
      size={size}
      leftIcon={iconPosition === "left" && icon}
      rightIcon={iconPosition === "right" && icon}
    >
      {text}
      {!text && icon && icon}
    </ButtonMantine>
  );
};
